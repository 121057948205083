import { Card, IconButton } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { paramsFilter } from "utils/ParamsFilter";

import { IWorkById } from "apis/response";
import Apis from "apis/remotes";

import { ColumnsType } from "antd/es/table";
import MDBox from "components/MDBox";

function TemplateList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [templateList, setTemplateList] = useState<IWorkById[]>([]);
  const [total, setTotal] = useState(0);

  // ✅ 특정 옵션 삭제 함수
  const handleRemoveOption = (workId: number) => {
    setTemplateList((prevWorks) => prevWorks.filter((work) => work.workId !== workId));
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/announcement-management/template-list${paramsFilter({
        page,
      })}`
    );
    setCurrentPage(page);
  };

  useEffect(() => {
    Apis.getTemplateLoadList({ page: currentPage, perPage: 10 })
      .then((resolve) => {
        setTemplateList(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  const tableColumn: ColumnsType<IWorkById> = [
    {
      title: "템플릿",
      dataIndex: "workId",
      key: "workId",
      ellipsis: true,
      render: (workId: string, record) => {
        return (
          <MDBox
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            `[{record.workId}] {record.title} | {record.address} /{record.pay?.toLocaleString()}원`
            <IconButton
              onClick={(event) => {
                // event.stopPropagation(); // 옵션 선택 방지
                handleRemoveOption(record.workId);
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </MDBox>
        );
      },
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <Table
          pagination={false}
          rowKey={(record) => record.workId}
          scroll={{ x: "max-content" }}
          dataSource={templateList}
          columns={tableColumn}
          footer={() => (
            <Pagination
              current={currentPage}
              onChange={handleOnChangePage}
              pageSize={10}
              total={total}
              showSizeChanger={false}
            />
          )}
        />
      </Card>
    </DashboardLayout>
  );
}
export default TemplateList;
