import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ListItem from "../ListItem";
import MDTypography from "components/MDTypography";
import { IGetUserById } from "apis/response";
import { useEffect, useState } from "react";
import Apis from "apis/remotes";
import MDImage from "components/MDImage";

interface Props {
  open: boolean;
  handleOpenState: Function;
  selectedApplicant: IGetUserById;
}

function DetailApplicantDialog({ open, handleOpenState, selectedApplicant }: Props): JSX.Element {
  const [newInsurance, setNewInsurance] = useState({
    insuranceDay: [0, 0, 0, 0],
    insuranceAmountAvg: [0, 0, 0, 0],
  });

  useEffect(() => {
    Apis.getUserInsuranceById(selectedApplicant.userId)
      .then((resolve) => {
        const data = resolve.data;
        const insuranceDays = [
          Number(data.threeMonthInsuranceDay),
          Number(data.sixMonthInsuranceDay),
          Number(data.oneYearInsuranceDay),
          Number(data.threeYearInsuranceDay),
        ];

        console.log(insuranceDays);
        const insuranceAmounts = [
          Number(data.threeMonthInsuranceAmount),
          Number(data.sixMonthInsuranceAmount),
          Number(data.oneYearInsuranceAmount),
          Number(data.threeYearInsuranceAmount),
        ];

        const insuranceAmountAvg = insuranceDays.map((days, index) => {
          const avg = days > 0 && insuranceAmounts[index] > 0 ? insuranceAmounts[index] / days : 0;
          return isNaN(avg) ? 0 : Math.floor(avg); // Ensure NaN is converted to 0
        });

        setNewInsurance({ insuranceDay: insuranceDays, insuranceAmountAvg });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedApplicant.userId]);

  return (
    <Dialog open={open} onClose={() => handleOpenState()} fullWidth>
      <DialogTitle>
        <MDBox display="flex" justifyContent="center">
          지원자정보 보기
        </MDBox>
      </DialogTitle>
      <DialogContent>
        <MDBox mb={3}>
          <MDTypography>
            {selectedApplicant?.name}({selectedApplicant?.gender === "male" ? "남" : "여"},{" "}
            {selectedApplicant?.birth})
          </MDTypography>
          {/* <ListItem label={"자기소개"} values={selectedApplicant?.introduce} /> */}
          {/* <ListItem
            label={"프로필 사진"}
            values={
              <MDImage
                image={selectedApplicant?.profile}
                alt="content_img"
                width={"100px"}
                height={"100px"}
              />
            }
          /> */}
          <ListItem
            label={"신분증"}
            values={
              <MDImage
                image={selectedApplicant?.idCard}
                alt="content_img"
                width={"100px"}
                height={"100px"}
              />
            }
          />
          <ListItem
            label={"안전교육 이수증"}
            values={
              <MDImage
                image={selectedApplicant?.safetyCertificate}
                alt="content_img"
                width={"100px"}
                height={"100px"}
              />
            }
          />
          <MDBox border="2px solid black" borderRadius="5px" overflow="hidden" mx={2}>
            <MDBox display="flex" bgColor="#f5f5f5">
              <MDBox width="20%" p={2} border={1}>
                <MDTypography fontWeight="bold">구분</MDTypography>
              </MDBox>
              <MDBox width="20%" p={2} border={1}>
                <MDTypography fontWeight="bold">3개월</MDTypography>
              </MDBox>
              <MDBox width="20%" p={2} border={1}>
                <MDTypography fontWeight="bold">6개월</MDTypography>
              </MDBox>
              <MDBox width="20%" p={2} border={1}>
                <MDTypography fontWeight="bold">1년</MDTypography>
              </MDBox>
              <MDBox width="20%" p={2} border={1}>
                <MDTypography fontWeight="bold">3년</MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex">
              <MDBox width="20%" p={2} border={1}>
                <MDTypography>근로일수</MDTypography>
              </MDBox>
              {newInsurance.insuranceDay.map((days, index) => (
                <MDBox key={index} width="20%" p={2} border={1}>
                  <MDTypography>{days}</MDTypography>
                </MDBox>
              ))}
            </MDBox>
            <MDBox display="flex">
              <MDBox width="20%" p={2} border={1}>
                <MDTypography>평균단가 (임금총액/근로일수)</MDTypography>
              </MDBox>
              {newInsurance.insuranceAmountAvg.map((amount, index) => (
                <MDBox key={index} width="20%" p={2} border={1}>
                  <MDTypography>{amount.toLocaleString()}</MDTypography>
                </MDBox>
              ))}
            </MDBox>
          </MDBox>
          {/* 다음의 항목은 현재 사용하지 않음
          <ListItem label={"전자서명 파일"} values={<a href={selectedApplicant.digitalSignature}>다운로드</a>} />
          <ListItem label={"비상 연락처 이름"} values={selectedApplicant.emergencyName} />
          <ListItem label={"비상 연락처 번호"} values={selectedApplicant.emergencyPhone} />
          */}
          {/* <ListItem label={"직종"} values={selectedApplicant?.occupation} />
          <ListItem label={"경력"} values={selectedApplicant?.career} />
          <ListItem
            label={"혈액형"}
            values={`(${selectedApplicant?.bloodRh})${selectedApplicant?.bloodType}`}
          />
          <ListItem
            label={"혈압"}
            values={`${selectedApplicant?.bloodPressureSys} / ${selectedApplicant?.bloodPressureDia}`}
          />
          <ListItem label={"연락처"} values={selectedApplicant?.phone} />
          <ListItem label={"이메일"} values={selectedApplicant?.email} />
          <ListItem
            label={"주소"}
            values={`${selectedApplicant?.firstAddress} ${selectedApplicant?.secondAddress}`}
          />
          <ListItem
            label={"계좌 정보"}
            values={`${selectedApplicant?.bankName} | ${selectedApplicant?.bankAccount}`}
          />
          <ListItem label={"생성 일시"} values={selectedApplicant?.createdAt} />
          <ListItem label={"로그인 타입"} values={selectedApplicant?.type} />
          <ListItem label={"상태"} values={selectedApplicant?.status} />
          <ListItem
            label={"이용약관 동의 여부"}
            values={selectedApplicant?.termsAgreed === 1 ? "동의함" : "동의안함"}
          /> */}
          {/* <ListItem
            label={"파트너 여부"}
            values={selectedApplicant?.isPartner === 1 ? "파트너" : "사용자"}
          /> */}
          {/* <ListItem
            label={"삭제 여부"}
            values={selectedApplicant?.isDeleted === 1 ? "삭제됨" : "삭제 안됨"}
          />
          {selectedApplicant?.deletedReason && (
            <ListItem label={"삭제 사유"} values={selectedApplicant?.deletedReason} />
          )} */}
          {/* <ListItem label={"비상연락처"} values={selectedApplicant?.emergencyContact} /> */}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => handleOpenState()} color="info">
          닫기
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DetailApplicantDialog;
