import {
  IPostAdministrators,
  IGetFilesUpload,
  getList,
  IGetUsers,
  IGetUserById,
  IGetAdministrators,
  IEmployments,
  IEmploymentsById,
  IWorks,
  IWorkById,
  fileUploadTarget,
  fileUplaodType,
  IWorkPostForm,
  IEmploymentForm,
  IMatchRecuit,
  IMatchApply,
  ISettlements,
  IUserSettlementById,
  UserCurrentMatchType,
  IMatchCurrentApplyFive,
  IMatchCurrentSix,
  IMatchEvaluationForm,
  IUserSettlement,
  IGetAdminById,
  // IExps,
  // IExpById,
  // IExpWork,
  // IExpWorkById,
  IBanners,
  IBannerById,
  IBannerForm,
  IQnas,
  IQnaById,
  IReviews,
  INewSettlement,
  INewSettlementById,
  IExpWorkList,
  IExpWorkUser,
  IExpWorkDayList,
  INewWork,
  WorkData,
  IDashBoardGraph,
  IGetAdministratorMy,
  INotice,
  IChat,
  IChatLog,
  IReportChat,
  IEvent,
  IChatNotice,
  IOfferUser,
  ICoupon,
  IBuddy,
  ISetting,
  IBugs,
  IGetUserAbsence,
  IUtils,
  IUtilDetails,
  IUtilApproves,
  IUtilApproveDetails,
  IUtilImages,
  IUtilImageById,
  IInsuranceDetail,
  ICodeListCity,
  ICodeListCityDistrict,
  IGetBanUsers,
  IGetUserOfferInsurance,
  IWorkFieldEvaluation,
  IWorkFieldEvaluationWorker,
  IPyeongtaek,
  IInsuranceStatics,
  getPyeongtaekList,
  getInsuranceUserList,
  IInsuranceStaticsById,
  IAdministratorPhoneData,
  IUtilTracking,
  IWorkPartnerList,
  IWorkPartnerListById,
} from "apis/response";
import { api } from "apis/axios";

const Apis = {
  /** 관리자 추가 */
  postAdministrator(option: any) {
    return api.post<IPostAdministrators>(`administrator`, option);
  },
  /** 관리자 정보 수정(본인) */
  putAdministrator(option: any) {
    return api.put(`administrator`, option);
  },
  /** 관리자 목록 조회 */
  getAdministrator(option: any) {
    return api.get<getList<IGetAdministrators>>(`administrator`, {
      params: option,
    });
  },
  /** 관리자 상세 조회 */
  getAdministratorById(id: string | number) {
    return api.get<IGetAdminById>(`administrator/${id}`);
  },
  /** 관리자 수정 */
  putAdministratorById(id: string | number, option: any) {
    return api.put(`administrator/${id}`, option);
  },
  /** 관리자 삭제 */
  deleteAdministrator(id: string | number) {
    return api.delete(`administrator/${id}`);
  },
  /** 관리자 비밀번호 변경 */
  putAdministratorPasswordChange(option: any) {
    return api.put(`administrator/password/change`, option);
  },
  /** 관리자 마이 페이지; */
  getAdministratorMy() {
    return api.get<IGetAdministratorMy>(`administrator/my`);
  },
  /** 구슬땀 설정 조회 */
  getAdministratorAdminSetting() {
    return api.get<getList<ISetting>>(`administrator/admin/setting`);
  },
  /** 구슬땀 설정 조회 */
  putAdministratorAdminSettingById(id: string | number, option: any) {
    return api.put<ISetting>(`administrator/admin/setting/${id}`, option);
  },

  /** 관리자 번호 조회 */
  getAdministratorAdminPhone(option: any) {
    return api.get<getList<IAdministratorPhoneData>>(`administrator/admin/phone`, {
      params: option,
    });
  },
  /** 관리자 번호 등록 */
  postAdministratorAdminPhone(option: any) {
    return api.post(`administrator/admin/phone`, option);
  },
  /** 관리자 번호 삭제 */
  deleteAdministratorAdminPhoneById(id: number) {
    return api.delete(`administrator/admin/phone/${id}`);
  },

  /** 관리자 로그인 세션 체크 */
  getAuth() {
    return api.get("auth");
  },
  /** 관리자 로그인 */
  postAuth(option: any) {
    return api.post("auth", option);
  },
  /** 관리자 로그아웃 */
  deleteAuth() {
    return api.delete("auth");
  },

  /** 대시보드 오늘 */
  getDashBoardToday(option: any) {
    return api.get<
      getList<WorkData> & {
        totalMaxRecruitment: number;
        totalWorkApplyCount: number;
        totalCurrentRecruitment: number;
        totalGoWorkCount: number;
        totalWorkAbsenceCount: number;
        totalUnaccountedCount: number;
      }
    >(`dashboard/today`, { params: option });
  },
  /** 대시보드 내일 */
  getDashBoardTomorrow(option: any) {
    return api.get<
      getList<WorkData> & {
        totalMaxRecruitment: number;
        totalWorkApplyCount: number;
        totalCurrentRecruitment: number;
        totalGoWorkCount: number;
        totalWorkAbsenceCount: number;
      }
    >(`dashboard/tomorrow`, { params: option });
  },
  /** 대시보드 그래프 */
  getDashBoardGraph() {
    return api.get<IDashBoardGraph>(`dashboard/graph`);
  },
  /** 오류 제보 */
  postDashBoardBug(option: any) {
    return api.post(`dashboard/bug`, option);
  },
  /** 신고한 오류 조회 */
  getDashBoardBug(option: any) {
    return api.get<getList<IBugs>>(`dashboard/bug`, { params: option });
  },
  /** 알파베이스 - 오류 조회 */
  getDashBoardAlpha(option: any) {
    return api.get<getList<IBugs>>(`dashboard/alpha`, { params: option });
  },
  /** 알파베이스 - 오류 수정 */
  putDashBoardAlphaById(id: string | number) {
    return api.put(`dashboard/alpha/${id}`);
  },

  /** 인력사무소 가입 */
  postEmployment(option: IEmploymentForm) {
    return api.post(`employment`, option);
  },
  /** 인력사무소 조회 */
  getEmployment(option: any) {
    return api.get<getList<IEmployments>>("employment", {
      params: option,
    });
  },
  /** 인력사무소 정보 수정 */
  putEmployment(id: string | number, option: any) {
    return api.put(`employment/${id}`, option);
  },
  /** 인력사무소 상세 조회 */
  getEmploymentById(id: string | number) {
    return api.get<IEmploymentsById>(`employment/${id}`);
  },
  /** 인력사무소 삭제 */
  deleteEmployment(id: string | number) {
    return api.delete(`employment/${id}`);
  },

  /** 인력사무소 비밀번호 변경 */
  putEmploymentPasswordById(id: string | number, option: any) {
    return api.put(`employment/password/${id}`, option);
  },

  /** Get pre-signed url */
  postFilesUpload({
    type,
    mimeType,
    imageUploadTarget,
    num = 1,
  }: {
    type: fileUplaodType;
    mimeType: string;
    imageUploadTarget: fileUploadTarget;
    num?: number;
  }) {
    return api.get<IGetFilesUpload[]>(`file/upload`, {
      params: {
        type,
        mimeType,
        imageUploadTarget,
        num,
      },
    });
  },

  /** Get file download */
  getFileDownLoad(option: any) {
    return api.get<IGetFilesUpload>(`file/download`, { params: option });
  },

  /** 유저 리스트 조회 */
  getUsers(option: any) {
    return api.get<getList<IGetUsers>>(`user`, { params: { ...option } });
  },

  /** 정지 유저 리스트 조회 */
  getBanUsers(option: any) {
    return api.get<getList<IGetBanUsers>>(`user/ban/list`, { params: { ...option } });
  },

  /** 고용보험 제안 알림톡 유저 조회 */
  getUserOfferInsurance() {
    return api.get<getList<IGetUserOfferInsurance>>(`user/offer/insurance`);
  },

  /** 고용보험 제안 알림톡 전송 */
  postUserOfferInsurance(option: any) {
    return api.post(`user/offer/insurance`, option);
  },

  /** 유저 상세조회 */
  getUserById(id: string | number) {
    return api.get<IGetUserById>(`user/${id}`);
  },
  /** 유저 상세조회 - 고용보험 */
  getUserInsuranceById(id: string | number) {
    return api.get<IInsuranceDetail>(`user/insurance/${id}`);
  },
  /** 유저 임시 승인 */
  putUserGeneralById(id: string | number, option: any) {
    return api.put(`user/general/${id}`, option);
  },
  /** 유저 buddy 조회 */
  getBuddy(id: string | number, option: any) {
    return api.get<getList<IBuddy>>(`user/buddy/${id}`, { params: option });
  },

  /** 결근 유저 리스트 조회 */
  getUserAbsenceList(option: any) {
    return api.get<getList<IGetUserAbsence>>(`user/absence/list`, { params: { ...option } });
  },

  /** 회원 정지 */
  postUserBanById(id: string | number, option: any) {
    return api.post(`user/ban/${id}`, option);
  },

  /** 회원 정지 해제 */
  putUserBanById(id: string | number) {
    return api.put(`user/ban/${id}`);
  },

  /** 유저 가입 승인 */
  putUserApprove(id: string | number) {
    return api.put(`user/approve/${id}`);
  },

  /** 지원 시간 조회 */
  getUtils(option: any) {
    return api.get<getList<IUtils>>(`util`, { params: option });
  },

  /** 지원 시간 상세 조회 */
  getUtilDetail(option: any) {
    return api.get<getList<IUtilDetails>>(`util/date`, { params: option });
  },

  /** 승인 시간 조회 */
  getUtilApproves(option: any) {
    return api.get<getList<IUtilApproves>>(`util/approve`, { params: option });
  },

  /** 승인 시간 상세 조회 */
  getUtilApproveDetails(option: any) {
    return api.get<getList<IUtilApproveDetails>>(`util/approve/date`, { params: option });
  },

  /** 공고 등록용 이미지 조회 */
  getUtilImages(option: any) {
    return api.get<getList<IUtilImages>>(`util/image`, { params: option });
  },

  /** 공고 등록용 이미지 생성 */
  postUtilImage(option: any) {
    return api.post(`util/image`, option);
  },
  /** 공고 등록용 랜덤 이미지 목록 */
  getUtilImageRandom(option: any) {
    return api.get<getList<IUtilImages>>(`util/image/random`, { params: option });
  },
  /** 공고 등록용 이미지 삭제 */
  deleteUtilImage(id: string | number) {
    return api.delete(`util/image/${id}`);
  },
  /** 공고 등록용 이미지 상세 조회 */
  getUtilImageById(id: string | number) {
    return api.get<IUtilImageById>(`util/image/detail/${id}`);
  },

  /** 트래킹 횟수 조회 */
  getUtilTracking() {
    return api.get<IUtilTracking>(`util/tracking`);
  },

  /** 공고 등록 */
  postWork(option: IWorkPostForm) {
    return api.post(`work`, option);
  },
  /** 공고 수정 */
  putWork(id: string | number, option: IWorkPostForm) {
    return api.put(`work/${id}`, option);
  },
  /** 공고 조회 */
  getWork(option: any) {
    return api.get<getList<IWorks>>("work", {
      params: option,
    });
  },
  /** new 공고 조회 */
  getNewWork(option: any) {
    return api.get<getList<INewWork>>(`work`, { params: option });
  },

  /** 공고 숨기기 */
  putWorkShowById(id: string | number) {
    return api.put(`work/show/${id}`);
  },

  /** new 예약 공고 조회 */
  getNewWorkReserve(option: any) {
    return api.get<getList<INewWork>>(`work/reserve`, { params: option });
  },
  /** 공고 상세 조회 */
  getWorkById(id: string | number) {
    return api.get<IWorkById>(`work/${id}`);
  },
  /** 공고 삭제 */
  deleteWork(id: string | number) {
    return api.delete(`work/${id}`);
  },
  /** 공고 마감 */
  putWorkOff(id: string | number) {
    return api.put(`work/off/${id}`);
  },
  /** 공고 자동 마감 ON/OFF
   *
   * 공고가 수동 마감되면 해당 공고는 직접 마감하기 전까지 마감되지 않음.
   */
  putWorkOnOffManual(id: string | number) {
    return api.put(`work/work-end/${id}`);
  },

  /** 공고 리스트 불러오기 */
  getWorkLoadList(option: any) {
    return api.get<getList<IWorkById>>(`work/load/list`, { params: option });
  },

  /** 공고 템플릿 리스트 불러오기 */
  getTemplateLoadList(option: any) {
    return api.get<getList<IWorkById>>(`work/template/list`, { params: option });
  },

  /** 현장 파트너 조회 */
  getWorkPartnerList(option: any) {
    return api.get<getList<IWorkPartnerList>>(`work/partner/list`, { params: option });
  },
  /** 현장 파트너 상세조회 */
  getWorkPartnerListById(id: number | string) {
    return api.get<getList<IWorkPartnerListById>>(`work/partner/list/${id}`);
  },
  /** 현장 파트너 on/off */
  putWorkPartnerListOnOff(option: any) {
    return api.put(`work/partner/list`, option);
  },

  /** 공고 강제 수정 */
  putWorkRecruit(id: string | number, option: any) {
    return api.put(`work/recruitment/${id}`, option);
  },

  /** 근무 확정인원 조회 */
  getMatchRecruit(id: string | number) {
    return api.get<getList<IMatchRecuit>>(`match/recruit/${id}`);
  },
  /** 현장 1~4단계 조회(지원/출근/퇴근) */
  getMatchCurrentApply(id: string | number, optoin: { status: UserCurrentMatchType }) {
    return api.get<getList<IMatchApply>>(`match/current-apply/${id}`, { params: optoin });
  },
  /** 현장 5단계 조회(입금) */
  getMatchCurrentApplyFive(id: string | number) {
    return api.get<getList<IMatchCurrentApplyFive>>(`match/current-apply-five/${id}`);
  },
  /** 현장 6단계 조회(평가) */
  getMatchCurrentApplySix(id: string | number) {
    return api.get<getList<IMatchCurrentSix>>(`match/current-apply-six/${id}`);
  },
  /** 매칭 전체 인원 조회 */
  // getMatchApply(option: any) {
  //   return api.get<getList<IMatchApply>>(`match/apply`, { params: option });
  // },
  /** 출근확정 */
  putMatchConfirmWork(id: string | number) {
    return api.put(`match/confirm-work/${id}`);
  },
  /** 출근확인 */
  putMatchGoWork(id: string | number) {
    return api.put(`match/go-work/${id}`);
  },
  /** 퇴근확인 */
  putMatchOffWork(id: string | number) {
    return api.put(`match/off-work/${id}`);
  },
  /** 유저평가 */
  postMatchEvaluation(option: IMatchEvaluationForm) {
    return api.post(`match/evaluation`, option);
  },
  /** 유저평가 수정 */
  putMatchEvaluationById(id: string | number, option: Pick<IMatchEvaluationForm, "score">) {
    return api.put(`match/evaluation/${id}`, option);
  },
  /** 결근 처리 */
  putMatchAbsenceById(id: string | number) {
    return api.put(`match/absence/${id}`);
  },
  /** 출근 확인 요청 알람 */
  postMatchGoWorkFcm(option: { userId: number; workId: number }) {
    return api.post(`match/go-work-fcm`, option);
  },
  /** 퇴근 확인 요청 알람 */
  postMatchOffWorkFcm(option: { userId: number; workId: number }) {
    return api.post(`match/off-work-fcm`, option);
  },

  /** 매칭 리스트 조회 */
  // getMatchs(option: any) {
  //   return api.get<getList<IMatchs>>(`match`, {
  //     params: option,
  //   });
  // },
  /** 공고지원 승인(지원 합격) */
  putMatchApprove(id: string | number) {
    return api.put(`match/approve/${id}`);
  },
  /** 결근 처리 출근으로 변경 */
  putMatchAbsenceCancelById(id: string | number) {
    return api.put(`match/absence/cancel/${id}`);
  },
  /** 공고지원 거절(지원 미승인)
   * 해당 공고 지원자를 마감또는 확정인원이 정해져서 패털티 없이 미승인.
   */
  putMatchReject(id: string | number) {
    return api.put(`match/reject/${id}`);
  },
  /** 패널티 - 공고지원 거절(지원 미승인)
   * 해당 공고 지원자를 악성 사용자라고 판단하여 패널티를 주고 미승인.
   */
  putMatchRejectPenalty(id: string | number) {
    return api.put(`match/reject-penalty/${id}`);
  },
  /** 지원확정 인원 취소
   * 확정된 인원에서 해당 사용자를 패널티 없이 제외한다.
   */
  putMatchRejectRecruit(id: string | number) {
    return api.put(`match/reject-recruit/${id}`);
  },
  /** 패널티 - 지원확정 인원 취소
   * 확정된 인원에서 해당 사용자를 악성 사용자라고 판단하여 제외한다.
   */
  putMatchRejectRecruitPenalty(id: string | number) {
    return api.put(`match/reject-recruit-penalty/${id}`);
  },

  /** 답변 작성 */
  postQnaById(id: string | number, option: any) {
    return api.post(`qna/${id}`, option);
  },
  /** 답변 조회 */
  getQnaById(id: string | number) {
    return api.get<IQnaById>(`qna/${id}`);
  },
  /** 답변 수정 */
  putQnaById(id: string | number, option: any) {
    return api.put(`qna/${id}`, option);
  },
  /** 답변 삭제 */
  deleteQnaById(id: string | number) {
    return api.delete(`qna/${id}`);
  },
  /** 문의/답변 조회 */
  getQna(option: any) {
    return api.get<getList<IQnas>>(`qna`, { params: option });
  },

  /** 리뷰 조회 */
  getReview(option: any) {
    return api.get<getList<IReviews>>(`review`, { params: option });
  },

  /** 입금완료 id -> settlementId */
  putSettlementDepositById(id: string | number) {
    return api.put(`settlement/deposit/${id}`);
  },

  /** 입금완료 id -> settlementId */
  putSettlementDepositCouponById(id: string | number) {
    return api.put(`settlement/deposit/coupon/${id}`);
  },

  /** 입금 수정 */
  putSettlementById(id: string | number, option: any) {
    return api.put(`settlement/${id}`, option);
  },
  /** 유저 정산 조회 id -> userId */
  getUserSettlemntById(id: string | number, option: any) {
    return api.get<getList<IUserSettlementById>>(`settlement/${id}`, { params: option });
  },
  /** 정산 조회 */
  getSettlements(options: any) {
    return api.get<getList<ISettlements>>(`settlement`, { params: options });
  },
  /** new 정산 조회 */
  getSettlement(option: any) {
    return api.get<
      getList<INewSettlement> & {
        totalSettlementUserCount: number;
        totalManDayCount: number;
        totalTotalAmount: number;
      }
    >(`settlement`, { params: option });
  },
  /** new 정산 상세 조회 */
  getSettlementById(id: string | number) {
    return api.get<getList<INewSettlementById>>(`settlement/${id}`);
  },
  /** 정산 조회(사용자) 미사용으로 추정*/
  getSettlementUserList(option: any) {
    return api.get<getList<IUserSettlement>>(`settlement/user/list`, { params: option });
  },

  /** 이력관리 조회 미사용으로 추정*/
  // getExp(option: any) {
  //   return api.get<getList<IExps>>(`experience`, { params: option });
  // },
  /** 이력관리 상세 조회 미사용으로 추정*/
  // getExpById(id: string | number, option: any) {
  //   return api.get<getList<IExpById>>(`experience/${id}`, { params: option });
  // },

  /** New 근로자 목록 조회 */
  getExpWorkList(option: any) {
    return api.get<getList<IExpWorkList>>(`experience/work/list`, { params: option });
  },
  /** New 근무중인 근로자 목록 조회 */
  getExpWorkingList(option: any) {
    return api.get<getList<IExpWorkList>>(`experience/working/list`, { params: option });
  },
  /** New 근로자 목록 상세 조회 상단(유저 상세 조회 정보) */
  getExpWorkListById(id: string | number) {
    return api.get<IExpWorkUser>(`experience/work/list/${id}`);
  },
  /** New 근로자 목록 상세 조회 하단(유저 근무 리스트 정보) */
  getExpWorkListDayById(id: string | number) {
    return api.get<getList<IExpWorkDayList>>(`experience/work/list/day/${id}`);
  },
  /** 근로내역 조회 미사용으로 추정*/
  // getExpWork(option: any) {
  //   return api.get<getList<IExpWork>>(`experience/work/list`, { params: option });
  // },
  /** 근로내역 상세 조회 미사용으로 추정*/
  // getExpWorkById(id: string | number, option: any) {
  //   return api.get<getList<IExpWorkById>>(`experience/work/list/${id}`, { params: option });
  // },

  /** 배너 등록 */
  postBanner(option: IBannerForm) {
    return api.post(`banner`, option);
  },
  /** 배너 조회 */
  getBanners(option: any) {
    return api.get<getList<IBanners>>(`banner`, { params: option });
  },
  /** 배너 상세 조회 */
  getBannerById(id: string | number) {
    return api.get<IBannerById>(`banner/${id}`);
  },
  /** 배너 수정 */
  putBanner(id: string | number, option: any) {
    return api.put(`banner/${id}`, option);
  },
  /** 배너 삭제 */
  deleteBanner(id: string | number) {
    return api.delete(`banner/${id}`);
  },
  /** 배너 On/Off */
  putBannerSwitch(id: string | number) {
    return api.put(`banner/switch/${id}`);
  },

  /** 쿠폰 생성 */
  postCoupon(option: any) {
    return api.post(`coupon`, option);
  },

  /** 쿠폰 개별 발급 */
  postCouponDistribute(option: any) {
    return api.post(`coupon/distribute`, option);
  },

  /** 쿠폰 전체 발급 */
  postCouponDistributeAll(id: string | number) {
    return api.post(`coupon/distribute/all/${id}`);
  },

  /** 쿠폰 조회 */
  getCoupons(options: any) {
    return api.get<getList<ICoupon>>(`coupon`, { params: options });
  },

  /** 쿠폰 상세 조회 */
  getCouponById(id: string | number) {
    return api.get<ICoupon>(`coupon/${id}`);
  },

  getCouponUser(option: any) {
    return api.get<getList<IOfferUser>>(`coupon/coupon/user`, { params: option });
  },

  /** 쿠폰 ON OFF */
  putCouponById(id: string | number) {
    return api.put(`coupon/${id}`);
  },

  deleteCoupon(id: string | number) {
    return api.delete(`coupon/${id}`);
  },

  /** 쿠폰 정산 조회 */
  getCouponSettlement(option: any) {
    return api.get(`settlement/coupon/settlement`, { params: option });
  },

  /** 공지사항 등록*/
  postNotice(option: any) {
    return api.post(`announcement`, option);
  },

  /** 공지사항 목록 조회 */
  getNotices(option: any) {
    return api.get<getList<INotice>>(`announcement`, { params: option });
  },

  /** 공지사항 상세 조회 */
  getNoticeById(id: string | number) {
    return api.get<INotice>(`announcement/${id}`);
  },

  /** 공지사항 수정 */
  putNotice(id: string | number, option: any) {
    return api.put(`announcement/${id}`, option);
  },

  /** 공지사항 삭제 */
  deleteNotice(id: string | number) {
    return api.delete(`announcement/${id}`);
  },

  /** 이벤트 등록*/
  postEvent(option: any) {
    return api.post(`event`, option);
  },

  /** 이벤트 목록 조회 */
  getEvents(option: any) {
    return api.get<getList<IEvent>>(`event`, { params: option });
  },

  /** 이벤트 상세 조회 */
  getEventById(id: string | number) {
    return api.get<IEvent>(`event/${id}`);
  },

  /** 이벤트 수정 */
  putEvent(id: string | number, option: any) {
    return api.put(`event/${id}`, option);
  },

  /** 이벤트 삭제 */
  deleteEvent(id: string | number) {
    return api.delete(`event/${id}`);
  },

  /** 일자리 대화방 목록 조회 */
  getWorkChats(option: any) {
    return api.get<getList<IChat>>(`chat/work`, { params: option });
  },

  /** 일대일 대화방 목록 조회 */
  getBuddyChats(option: any) {
    return api.get<getList<IChat>>(`chat/buddy`, { params: option });
  },

  /** 대화방 상세조회 */
  getChat(id: string | number) {
    return api.get(`chat/${id}`);
  },

  /** 대화 기록 조회 */
  getChatLogs(id: string | number, option: any) {
    return api.get<getList<IChatLog>>(`chat/log/${id}`, { params: option });
  },

  /** 신고받은 대화 목록 조회 */
  getReportChats(option: any) {
    return api.get<getList<IReportChat>>(`chat/report`, { params: option });
  },

  /** 신고받은 대화 상세 조회 */
  getReportChat(id: string | number) {
    return api.get(`chat/report/${id}`);
  },

  /** 공지 대화 전송 */
  postChatNotice(option: any) {
    return api.post(`chat/notice`, option);
  },

  /** 공지 대화 전송 */
  postChatNoticeById(id: string | number, option: any) {
    return api.post(`chat/notice/${id}`, option);
  },

  /** 공지 대화 조회 */
  getChatNotice(option: any) {
    return api.get<getList<IChatNotice>>(`chat/notice`, { params: option });
  },

  /** 시/도 코드 테이블 */
  getCodeListCity() {
    return api.get<getList<ICodeListCity>>(`codelist/city`);
  },

  /** 시/군/구 코드 테이블 */
  getCodeListCityDistrict(cityName: string) {
    return api.get<getList<ICodeListCityDistrict>>(`codelist/city/${cityName}`);
  },

  /** 특별 제안 */
  postOffer(option: any) {
    return api.post(`work/offer`, option);
  },

  /** 특별 제안 유저 조회 */
  getOfferUser(option: any) {
    return api.get<getList<IOfferUser>>(`work/offer/user`, { params: option });
  },

  /** 현장 평가 목록 조회 */
  getWorkFieldEvaluation(option: any) {
    return api.get<getList<IWorkFieldEvaluation>>(`field/evaluation`, { params: option });
  },

  /** 현장 평가 상세 */
  getWorkFieldEvaluationById(id: string | number) {
    return api.get<getList<IWorkFieldEvaluationWorker>>(`field/evaluation/${id}`);
  },

  // /** 고용보험 인증회원 */
  // getInsuranceCertification(option: any) {
  //   return api.get<getList<IInsuranceCertification>>(`util/insurance`, { params: option });
  // },

  /** 고용보험 인증회원 통계 */
  getInsuranceStatics(option: any) {
    return api.get<getInsuranceUserList<IInsuranceStatics>>(`util/insurance`, {
      params: option,
    });
  },

  /** 고용보험 인증회원 통계 상세 */
  getInsuranceStaticsById(day: string, option: any) {
    return api.get<getList<IInsuranceStaticsById>>(`util/insurance/${day}`, { params: option });
  },

  /** 평택 신규 회원 통계 */
  getPyeongtaek(option: any) {
    return api.get<getPyeongtaekList<IPyeongtaek>>(`util/pyeongtaek`, { params: option });
  },
};

export default Apis;
