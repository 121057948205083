const toDataURL = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const downloadFile = async (url: string, fileName?: string) => {
  try {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = fileName ?? "download";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.log(error);
  }
};
export const handleDownloadClick = (url: string) => {
  fetch(url, {
    method: "GET",
  })
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);

      const aTag = document.createElement("a");

      aTag.href = blobURL;
      aTag.download = "image.jpg";

      aTag.click();
    })
    .catch((e) => {
      console.error(e);
    });
};
